import React, { Component } from "react";
import axios from "axios";
import classes from "./UpcomingTests.module.css";
import Button from "../../Shared/Button/Button";
import { Link } from "react-router-dom";

class UpcomingTests extends Component {
  constructor() {
    super();
    this.state = {
      dates: ["", ""],
    };
    this.date_sort_asc = (date1, date2) => {
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    };
    this.months = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };
  }

  componentDidMount() {
    axios
      .get("https://quantumtutors-2b216.firebaseio.com/dates.json")
      .then((res) => {
        let dates = [];
        for (let key in res.data) {
          dates.push(
            new Date(
              parseInt(res.data[key]["date"].substring(4, 8)),
              parseInt(res.data[key]["date"].substring(0, 2)) - 1,
              parseInt(res.data[key]["date"].substring(2, 4))
            )
          );
        }
        dates.sort(this.date_sort_asc);
        this.setState(
          {
            dates: [
              dates[0]
                ? this.months[dates[0].getMonth() + 1] +
                  " " +
                  dates[0].getUTCDate() +
                  ", " +
                  dates[0].getFullYear()
                : "",
              dates[1]
                ? this.months[dates[1].getMonth() + 1] +
                  " " +
                  dates[1].getUTCDate() +
                  ", " +
                  dates[1].getFullYear()
                : "",
            ],
          },
          () => console.log(this.state)
        );
      })
      .catch((err) => {
        alert(err);
      });
  }
  render() {
    return (
      <div className={classes.div}>
        <div className={classes.leftDiv}>
          <h1>Upcoming Quantum Test Dates</h1>
          <button>{this.state.dates[0]}</button>
          <button>{this.state.dates[1]}</button>
          <Link
            to="/sign-up-for-a-practice-test"
            style={{ textDecoration: "none" }}
          >
            <button>View More</button>
          </Link>
        </div>
        <div className={classes.rightDiv}>
          <p>
            We don’t mind if you take a practice test before committing to
            lessons – rather, we encourage it! We provide practice tests for all
            college admission tests – including the SAT and ACT – not to mention
            private school admission tests – including the ISEE and HSPT.
          </p>
          <Link
            to="/sign-up-for-a-practice-test"
            style={{ textDecoration: "none" }}
          >
            <Button>Sign Up For A Practice Test</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default UpcomingTests;
