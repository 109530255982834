import React from "react";
import classes from "./Parallellogram.module.css";

const Parallellogram = (props) => {
  const background = props.background;
  return (
    <div
      className={classes.div}
      style={{ backgroundImage: "url(" + background + ")" }}
    >
      {props.children}
    </div>
  );
};

export default Parallellogram;
