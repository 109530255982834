import React, { Component, Fragment } from "react";
import PageHeader from "../Shared/PageHeader/PageHeader";
import background from "../../images/bg-team.jpg";
import Parallellogram from "../Shared/Parallellogram/Parallellogram";
import blueBackground from "../../images/parallellogramBlue.jpg";
import orangeP from "../../images/parallellogramOrange.jpg";
import ContactForm from "./ContactForm/ContactForm";

class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <PageHeader
          background={background}
          header="Contact Us"
          page="Contact Us"
          text="Have a question or ready to book your first session?"
        />
        <Parallellogram background={blueBackground}>
          <h1>Get In Touch</h1>
          <p>Call us at 1-866-825-5069</p>
          <p>Text us at 1-323-839-8393</p>
          <p>Email Inquiries to: anna@myquantumtutor.com</p>
          <p style={{ paddingBottom: "150px" }}>
            Address: 15928 Ventura Blvd. Suite 204, Encino CA 91436
          </p>
        </Parallellogram>
        <ContactForm />
        <Parallellogram background={orangeP}>
          <h2>
            I want to say something about how you've been helping me for years
            and been so patient and have met my needs in the most professional
            manner and have taught me valuable skills and fundamentals in order
            to be successful in my academic career. Thanks for the help from
            elementary school all the way through college.
          </h2>
          <h4 style={{ paddingBottom: "0" }}>- Beverly Hills High Student</h4>
          <h4>Now at UCSB!</h4>
        </Parallellogram>
      </Fragment>
    );
  }
}

export default ContactUs;
