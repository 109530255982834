import React from "react";
import classes from "./TutorCard.module.css";
import classes2 from "../../Shared/sharedStyles.module.css";
import teamIcon from "../../../images/icon-team.svg";

const TutorCard = (props) => (
  <div>
    <div className={classes.name}>
      <img src={teamIcon} alt="" />
      <h2>{props.name}</h2>
    </div>

    <p className={classes2.text} style={{ marginTop: "0" }}>
      {props.text}
    </p>
    <hr />
  </div>
);

export default TutorCard;
