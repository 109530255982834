import React from "react";
import books from "../../../images/books.png";
import step1 from "../../../images/step1.png";
import step2 from "../../../images/step2.png";
import step3 from "../../../images/step3.png";
import classes from "./HowWeWork.module.css";

const HowWeWork = () => (
  <React.Fragment>
    <div className={classes.div}>
      <img src={books} alt="Books" />
      <h1>How We Work</h1>
      <p>
        Every student learns and works differently - and we embrace those
        differences. Here’s how we determine how to best set up students for
        success:
      </p>
    </div>
    <div className={classes.div2}>
      <img src={step1} alt="Step One" />
      <p>
        We initiate a practice test to see where the student’s skills and
        challenges are
      </p>
    </div>
    <div className={classes.div2}>
      <img src={step2} alt="Step Two" />
      <p>
        We review results as well as the individual’s goals, needs and
        requirements for his or her school
      </p>
    </div>
    <div className={classes.div2}>
      <img src={step3} alt="Step Three" />
      <p>
        After collecting all of this information, we design a personalized
        education plan to help each student succeed
      </p>
    </div>
  </React.Fragment>
);

export default HowWeWork;
