import React, { Component } from "react";
import classes from "../../Shared/PracticeTestForm/PracticeTestForm.module.css";
import emailjs from "emailjs-com";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "../../Shared/Button/Button";
import image from "../../../images/contact.jpg";
import Checkbox from "@material-ui/core/Checkbox";

class ContactForm extends Component {
  state = {
    name: "",
    workWithQuant: false,
    studPar: true,
    phone: "",
    email: "",
    pracTests: false,
    consult: false,
    academicTutoring: false,
    testPrep: false,
    pod: false,
    other: false,
  };

  inputChangedHandler = (event, id) => {
    switch (id) {
      case "name":
        this.setState({ name: event.target.value });
        break;
      case "email":
        this.setState({ email: event.target.value });
        break;
      case "testDate":
        this.setState({ testDate: event.target.value });
        break;
      case "phone":
        this.setState({ phone: event.target.value });
        break;
      case "workWithQuant":
        this.setState({ workWithQuant: event.target.value === "Yes" });
        break;
      case "studPar":
        this.setState({ studPar: event.target.value === "Student" });
        break;
      case "pracTests":
        this.setState((prevState) => ({ pracTests: !prevState.pracTests }));
        break;
      case "consult":
        this.setState((prevState) => ({ consult: !prevState.consult }));
        break;
      case "academicTutoring":
        this.setState((prevState) => ({
          academicTutoring: !prevState.academicTutoring,
        }));
        break;
      case "testPrep":
        this.setState((prevState) => ({ testPrep: !prevState.testPrep }));
        break;
      case "pod":
        this.setState((prevState) => ({ pod: !prevState.pod }));
        break;
      case "other":
        this.setState((prevState) => ({ other: !prevState.other }));
        break;
      default:
        break;
    }
  };

  formSubmitHandler = () => {
    emailjs.send(
      "gmail_service",
      "quantum_tutors_contact_form",
      {
        name: this.state.name,
        workWithQuant: this.state.workWithQuant,
        studPar: this.state.studPar === true ? "Student" : "Parent",
        email: this.state.email,
        phone: this.state.phone,
        testDate: this.state.testDate,
        pracTests: this.state.pracTests === true ? "Practice Tests," : null,
        consult: this.state.consult === true ? "Consultation, " : null,
        academicTutoring:
          this.state.academicTutoring === true ? "Academic Tutoring, " : null,
        testPrep: this.state.testPrep === true ? "Test Prep, " : null,
        pod: this.state.pod === true ? "Forming a Pod, " : null,
        other: this.state.other === true ? "Other" : null,
      },
      "user_FIMaGb6x4xX7xwk9t5CMK"
    );

    emailjs
      .send(
        "gmail",
        "contactconfirmation",
        {
          name: this.state.name,
          email: this.state.email,
        },
        "user_VkefG9Vg4MyMgutkS6DRW"
      )
      .then(
        (response) => {
          alert("Your response was received. We will contact you soon!");
        },
        function (error) {
          alert("An error occurred: " + error);
        }
      );
  };

  render() {
    return (
      <React.Fragment>
        <h1 style={{ textAlign: "center" }}>Submit a Request</h1>
        <div className={classes.div}>
          <form className={classes.form}>
            <FormControl>
              <InputLabel color="secondary">Name (first and last)</InputLabel>
              <Input
                color="secondary"
                value={this.state.name}
                onChange={(event) => this.inputChangedHandler(event, "name")}
                style={{ marginBottom: "10px" }}
              />
            </FormControl>
            <FormControl>
              <FormLabel color="secondary">
                Do you currently work with Quantum Tutors?
              </FormLabel>
              <RadioGroup>
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                  checked={this.state.workWithQuant}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "workWithQuant")
                  }
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                  checked={!this.state.workWithQuant}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "workWithQuant")
                  }
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel color="secondary">
                Are you a student or a parent?
              </FormLabel>
              <RadioGroup>
                <FormControlLabel
                  value="Student"
                  control={<Radio />}
                  label="Student"
                  checked={this.state.studPar}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "studPar")
                  }
                />
                <FormControlLabel
                  value="Parent"
                  control={<Radio />}
                  label="Parent"
                  checked={!this.state.studPar}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "studPar")
                  }
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <InputLabel color="secondary">Email</InputLabel>
              <Input
                color="secondary"
                value={this.state.email}
                onChange={(event) => this.inputChangedHandler(event, "email")}
                style={{ marginBottom: "10px" }}
              />
            </FormControl>
            <FormControl>
              <InputLabel color="secondary">Phone</InputLabel>
              <Input
                color="secondary"
                value={this.state.phone}
                onChange={(event) => this.inputChangedHandler(event, "phone")}
                style={{ marginBottom: "10px" }}
              />
            </FormControl>
            <FormLabel component="legend">
              What are you interested in?
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.pracTests}
                  value={this.state.pracTests}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "pracTests")
                  }
                />
              }
              label="Practice Tests"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.consult}
                  value={this.state.consult}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "consult")
                  }
                />
              }
              label="Consultation"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.academicTutoring}
                  value={this.state.academicTutoring}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "academicTutoring")
                  }
                />
              }
              label="Academic Tutoring"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.testPrep}
                  value={this.state.testPrep}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "testPrep")
                  }
                />
              }
              label="Test Prep"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.pod}
                  value={this.state.pod}
                  onChange={(event) => this.inputChangedHandler(event, "pod")}
                />
              }
              label="Forming a Microschool (Pod)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.other}
                  value={this.state.other}
                  onChange={(event) => this.inputChangedHandler(event, "other")}
                />
              }
              label="Other"
            />
            <Button onClick={this.formSubmitHandler}>Submit</Button>
          </form>
          {window.innerWidth > 900 ? <img src={image} alt="" /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default ContactForm;
