import React, { Component } from "react";
import NavItem from "./NavItem/NavItem";
import classes from "./Navbar.module.css";
import quantumLogo from "../../images/quantumtutors_logo.png";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown/Dropdown";

class Navbar extends Component {
  state = {
    scrolled: false,
    servicesOpen: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 60;
      if (!isTop && !this.state.scrolled) {
        this.setState({ scrolled: true });
      } else if (isTop && this.state.scrolled) {
        this.setState({ scrolled: false });
      }
    });
    window.addEventListener("click", () => {
      this.toggleServicesOff();
    });
  }

  toggleServicesOn = (show) => {
    if (show !== this.state.servicesOpen) {
      this.setState({ servicesOpen: show });
    }
  };

  toggleServicesOff = () => {
    this.setState({ servicesOpen: false });
  };

  render() {
    return (
      <div>
        <ul
          className={
            this.state.scrolled
              ? classes.Navbar + " " + classes.scrolled
              : classes.Navbar
          }
        >
          <Link to="/">
            <img
              src={quantumLogo}
              alt="Quantum Tutors"
              className={classes.img}
              onMouseEnter={this.toggleServicesOff}
            />
          </Link>
          <NavItem
            link="/services"
            scrolled={this.state.scrolled}
            hover={() => this.toggleServicesOn(true)}
          >
            Services
          </NavItem>
          <NavItem
            link="/our-method"
            scrolled={this.state.scrolled}
            hover={this.toggleServicesOff}
          >
            Our Method
          </NavItem>
          <NavItem
            link="/meet-the-team"
            scrolled={this.state.scrolled}
            hover={this.toggle}
          >
            Meet The Team
          </NavItem>
          <NavItem
            link="/testimonials"
            scrolled={this.state.scrolled}
            hover={this.toggle}
          >
            Testimonials
          </NavItem>
          <NavItem
            link="/sign-up-for-a-practice-test"
            scrolled={this.state.scrolled}
            hover={this.toggleServicesOff}
          >
            Take A Practice Test?
          </NavItem>
          <NavItem
            link="/contact"
            scrolled={this.state.scrolled}
            hover={this.toggleServicesOff}
          >
            Contact Us
          </NavItem>
        </ul>
        <Dropdown
          services={this.state.servicesOpen}
          tutoring={this.state.tutoringOpen}
          scrolled={this.state.scrolled}
          offHover={this.toggleBoth}
        />
      </div>
    );
  }
}

export default Navbar;
