import React, { Component } from "react";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import background from "../../../images/page-39bg.jpg";
import ACTImage from "../../../images/sar-img.jpg";
import Parallellogram from "../../Shared/Parallellogram/Parallellogram";
import GreenP from "../../../images/parallellogramGreen.jpg";
import GetStarted from "../../Shared/GetStarted/GetStarted";
import classes from "../../Shared/sharedStyles.module.css";
import TestForm from "../../Shared/PracticeTestForm/PracticeTestForm";

class ACT extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <PageHeader
          background={background}
          header="ACT"
          page="ACT"
          text={
            "The ACT is a fast-paced exam with 5 sections: English, Math, Reading, Science and an Essay. \n\n The best way to determine if the ACT is the best option for you is to take a practice test. ACT practice tests are free, and are followed by a director analysis to help you choose which test is best suited for you. That way, before signing up with us, you know exactly where you stand and we know where to begin."
          }
        />
        <img src={ACTImage} alt="ACT" className={classes.img} />
        <Parallellogram background={GreenP}>
          <h2>
            “Anna - I must say that you and Jordan have arrived like angels and
            lit my son’s internal pilot.”
          </h2>
          <h4>– A Calabasas Parent</h4>
        </Parallellogram>
        <TestForm />
        <GetStarted />
      </div>
    );
  }
}

export default ACT;
