import React, { Component } from "react";
import { HashRouter, Route } from "react-router-dom";
import Homepage from "./Components/Homepage/Homepage.js";
import Navbar from "./Components/Navbar/Navbar";
import Toolbar from "./Components/Navbar/Toolbar";
import OurMethod from "./Components/OurMethod/OurMethod";
import MeetTheTeam from "./Components/MeetTheTeam/MeetTheTeam";
import ContactUs from "./Components/ContactUs/ContactUs";
import Services from "./Components/Services/Services";
import Academic from "./Components/Services/Academic/Academic";
import ACT from "./Components/Services/ACT/ACT";
import SATPSAT from "./Components/Services/SATPSAT/SATPSAT";
import ISEEHSPTSSAT from "./Components/Services/ISEEHSPTSSAT/ISEEHSPTSSAT";
import PracticeTests from "./Components/PracticeTests/PracticeTests";
import Axios from "axios";
import Testimonials from "./Components/Testimonials/testimonials.js";

class App extends Component {
  constructor() {
    super();
    this.state = {
      cards1: [],
      cards2: [],
    };
    this.compare = (tutor1, tutor2) => {
      if (Object.keys(tutor1) > Object.keys(tutor2)) {
        return 1;
      } else if (Object.keys(tutor1) < Object.keys(tutor2)) {
        return -1;
      } else {
        return 0;
      }
    };
    Axios.get("https://quantumtutors-2b216.firebaseio.com/tutorCards.json")
      .then((res) => {
        let col1 = [];
        let col2 = [];
        let colSwitch = true;
        for (let key in res.data) {
          if (colSwitch) {
            col1.push(res.data[key]);
          } else {
            col2.push(res.data[key]);
          }
          colSwitch = !colSwitch;
        }
        col1.sort(this.compare);
        col2.sort(this.compare);
        this.setState({ cards1: col1, cards2: col2 });
      })
      .catch((err) => {
        alert(err);
      });
  }
  render() {
    return (
      <HashRouter>
        <Navbar />
        <Toolbar />
        <Route path="/" exact component={Homepage}></Route>
        <Route path="/our-method" exact component={OurMethod}></Route>
        <Route path="/testimonials" exact component={Testimonials}></Route>
        <Route
          path="/meet-the-team"
          exact
          component={() => (
            <MeetTheTeam
              cards1={this.state.cards1}
              cards2={this.state.cards2}
            />
          )}
        ></Route>
        <Route path="/contact" exact component={ContactUs}></Route>
        <Route path="/services" exact component={Services} />
        <Route path="/act-preparation" exact component={ACT} />
        <Route path="/sat-psat" exact component={SATPSAT} />
        <Route path="/isee-hspt-ssat" exact component={ISEEHSPTSSAT} />
        <Route path="/academic" exact component={Academic} />
        <Route
          path="/sign-up-for-a-practice-test"
          exact
          component={PracticeTests}
        />
        <Route
          exact
          path="/pod"
          render={() =>
            (window.location =
              "https://mailchi.mp/myquantumtutor/your-solution-to-an-all-online-school-year")
          }
        />
      </HashRouter>
    );
  }
}

export default App;
