import React, { Fragment, Component } from "react";
import PageHeader from "../Shared/PageHeader/PageHeader";
import HowWeWork from "./HowWeWork/HowWeWork";
import Parallellogram from "../Shared/Parallellogram/Parallellogram";
import KidsLearnDifferently from "../Shared/KidsLearnDifferently/KidsLearnDifferently";
import GetStarted from "../Shared/GetStarted/GetStarted";
import background from "../../images/our-method-bg.jpg";
import quoteBackground from "../../images/parallellogramOrange.jpg";

class OurMethod extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const subheadingText =
      "Personalized. Bespoke. Tailored to your child and budget.";
    const subheadingSmallText =
      "Quantum is your home for personalized, expert Test Preparation, College Counseling and hands-on Academic Mentorship for students K-12.";
    return (
      <Fragment>
        <PageHeader
          subheader={
            window.innerWidth > 1200 ? (
              <>
                <h1>{subheadingText}</h1>
                <h3 style={{ fontWeight: "100" }}>{subheadingSmallText}</h3>
              </>
            ) : (
              <>
                <h3 style={{ fontWeight: "100" }}>{subheadingText}</h3>{" "}
                <p>{subheadingSmallText}</p>
              </>
            )
          }
          background={background}
          header="Our Method"
          page="Our Method"
          text="
      We know your child deserves the best, and working one on one is our style. Our mission is to provide professional guidance and support to students
      and families as they navigate their way through elementary, middle and
      high school and prepare for college. At the heart of our mission is
      enhancing and distilling each student’s true potential inside and outside
      the classroom. Our focus is on delivering an individualized service
      tailored to the unique educational needs of each student and family."
        />
        <HowWeWork />
        <Parallellogram background={quoteBackground}>
          <h2>
            “Rob can explain to me in one hour what my teacher tries to explain
            to me in a month.”
          </h2>
          <h4>- A Buckley Student</h4>
        </Parallellogram>
        <KidsLearnDifferently
          title="We also specialize in working with kids that have learning differences."
          text="In addition, we offer educational counseling (including focus on test anxiety management and organizational skills), collegiate tutoring, and Skype class offerings. Please let us know if you’d like more information about any of these services."
        />
        <Parallellogram background={quoteBackground}>
          <h2>
            “Our experience with Quantum Tutoring was phenomenal. My daughter
            happened to work with 2 different tutors, and both truly did a
            fantastic job of preparing my daughter for her SAT & ACT exams. My
            daughter felt she really connected with her tutors & was fully
            prepared for the tests. We’ll be calling next year for my son.”
          </h2>
          <h4>– Louisville High Parent</h4>
        </Parallellogram>
        <GetStarted />
      </Fragment>
    );
  }
}

export default OurMethod;
