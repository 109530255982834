import React from "react";
import classes from "./PodTutoring.module.css";
import { Link } from "react-router-dom";

const Grads2020 = () => (
  <div className={classes.Text}>
    <h1>Introducing Small Group (Pod) Tutoring!</h1>
    <p>
      Your solution to an "All Online" school year through Quantum Tutors! Visit
      our{" "}
      <Link
        style={{ textDecoration: "none", color: "#57a9cf", fontWeight: "bold" }}
        to="/pod"
      >
        Pod Tutoring page
      </Link>{" "}
      to learn more. Or call 1-866-825-5069.
    </p>
  </div>
);

export default Grads2020;
