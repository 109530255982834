import React from "react";
import InfoBox from "./InfoBox/InfoBox";
import blackboard from "../../images/blackboard.png";
import university from "../../images/university.png";
import exam from "../../images/exam.png";
import classes from "./InfoBoxes.module.css";

const InfoBoxes = () => (
  <div className={classes.Boxes}>
    <InfoBox
      image={blackboard}
      color="rgba(221, 130, 221, 0.6)"
      heading="Standardized Testing"
      text="We have easy-to-follow SAT, ACT, ISEE and HPST programs 
      to help students succeed with amazing scores. It’s not all about 
      being smart -- it’s about being prepared ahead of time."
    />
    <InfoBox
      image={university}
      color="rgba(22, 195, 252, 0.7)"
      heading="College Services"
      text="We know what top colleges are looking for, and we know 
        what it takes to get students prepared. We aid students in preparing 
        for college applications from essay writing to making your application 
        stand out."
    />
    <InfoBox
      image={exam}
      color="rgba(255, 155, 5, 0.7)"
      heading="K-12 Subject Tutoring"
      text="Science, math, humanities -- you name it, we cover it. We offer
         tutoring for all grade levels, including honors and AP courses."
    />
  </div>
);

export default InfoBoxes;
