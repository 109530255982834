import React, { Component } from "react";
import classes from "./QuotesHome.module.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Transition from "react-transition-group/Transition";

class QuotesHome extends Component {
  state = {
    currQuote: 0,
  };
  backHandler = () => {
    this.setState((prevState) => ({
      currQuote: (prevState.currQuote + 2) % 3,
    }));
  };
  forwardHandler = () => {
    this.setState((prevState) => ({
      currQuote: (prevState.currQuote + 1) % 3,
    }));
  };
  render() {
    return (
      <div className={classes.div}>
        <ArrowBackIosIcon
          color="secondary"
          fontSize="large"
          style={{ margin: "auto 0" }}
          onClick={this.backHandler}
        />
        <div>
          <Transition in={this.state.currQuote === 0} timeout={400}>
            {(state) => (
              <div
                style={{
                  opacity: state === "entered" ? 1 : 0,
                  transition: "0.4s",
                  display:
                    state === "exited"
                      ? "none"
                      : state === "entering"
                      ? "none"
                      : "block",
                }}
              >
                <h2>
                  "Rob can explain to me in one hour what my teacher tries to
                  explain to me in a month"
                </h2>
                <p>- A Buckley Student</p>
              </div>
            )}
          </Transition>
          <Transition in={this.state.currQuote === 1} timeout={400}>
            {(state) => (
              <div
                style={{
                  opacity: state === "entered" ? 1 : 0,
                  transition: "0.4s",
                  display:
                    state === "exited"
                      ? "none"
                      : state === "entering"
                      ? "none"
                      : "block",
                }}
              >
                <h2>
                  "Anna - I must say that you and Jordan have arrived like
                  angels and lit my son's internal pilot."
                </h2>
                <p>- A Calabass Parent</p>
              </div>
            )}
          </Transition>
          <Transition in={this.state.currQuote === 2} timeout={400}>
            {(state) => (
              <div
                style={{
                  opacity: state === "entered" ? 1 : 0,
                  transition: "0.4s",
                  display:
                    state === "exited"
                      ? "none"
                      : state === "entering"
                      ? "none"
                      : "block",
                }}
              >
                <h2>
                  My tutor was extremely helpful in the process of preparing for
                  SATs. She was very professional while still keeping all of our
                  sessions fun and engaging
                </h2>
                <p>- A Brentwood Student</p>
              </div>
            )}
          </Transition>
        </div>
        <ArrowForwardIosIcon
          color="secondary"
          fontSize="large"
          style={{ margin: "auto 0" }}
          onClick={this.forwardHandler}
        />
      </div>
    );
  }
}

export default QuotesHome;
