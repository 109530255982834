import React, { Component } from "react";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import background from "../../../images/page-39bg.jpg";
import topImage from "../../../images/academic-img.jpg";
import BlueP from "../../../images/parallellogramBlue.jpg";
import Parallellogram from "../../Shared/Parallellogram/Parallellogram";
import GetStarted from "../../Shared/GetStarted/GetStarted";
import classes from "../../Shared/sharedStyles.module.css";
import TestForm from "../../Shared/PracticeTestForm/PracticeTestForm";

class Academic extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <PageHeader
          background={background}
          header="Academic"
          page="Academic"
          text="Between schoolwork and extracurricular activities, being a student can be overwhelming, and academic assistance needs can vary and change over time. That’s why we do not require an upfront commitment to a package of lessons. Whether you’re looking for weekly lessons or more intermittent help such as assistance on writing a paper or studying for an AP exam, a director will happily discuss your situation and recommend a positive and resourceful solution."
        />
        <img src={topImage} alt="" className={classes.img} />
        <Parallellogram background={BlueP}>
          <h2>
            “Our experience with Quantum Tutoring was phenomenal. My daughter
            happened to work with 2 different tutors, and both truly did a
            fantastic job of preparing my daughter for her SAT & ACT exams. My
            daughter felt she really connected with her tutors & was fully
            prepared for the tests. We’ll be calling next year for my son.”
          </h2>
          <h4>- Louisville High Parent</h4>
        </Parallellogram>
        <TestForm />
        <GetStarted />
      </div>
    );
  }
}

export default Academic;
