import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavItem.module.css";

const NavItem = (props) => (
  <li
    className={
      props.scrolled
        ? classes.NavItem + " " + classes.scrolled
        : classes.NavItem
    }
  >
    <NavLink
      to={props.link}
      exact
      activeClassName={classes.active}
      onMouseEnter={props.hover}
    >
      {props.children}
    </NavLink>
  </li>
);

export default NavItem;
