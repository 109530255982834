import React from "react";
import Button from "@material-ui/core/Button";

const button = (props) => (
  <Button
    variant="contained"
    color="primary"
    style={{
      color: "white",
      paddingLeft: "50px",
      paddingRight: "50px",
      paddingTop: "8px",
      paddingBottom: "8px",
    }}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
);

export default button;
