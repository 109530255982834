import React from "react";
import classes from "./GetStarted.module.css";
import Button from "../Button/Button";
import quantumLogo from "../../../images/quantumtutors_logo.png";
import { Link } from "react-router-dom";

const GetStarted = () => (
  <div className={classes.div}>
    <h1>Ready to Get Started?</h1>
    <Link to="/contact" style={{ textDecoration: "none" }}>
      <Button>contact us</Button>
    </Link>
    <img src={quantumLogo} alt="Quantum Tutors" />
  </div>
);

export default GetStarted;
