import React, { Component, Fragment } from "react";
import classes from "./Homepage.module.css";
import PodTutoring from "./PodTutoring/PodTutoring";
import InfoBoxes from "../InfoBoxes/InfoBoxes";
import UpcomingTests from "./UpcomingTests/UpcomingTests";
import OurValuesHome from "./OurValuesHome/OurValuesHome";
import KidsLearnDifferently from "../Shared/KidsLearnDifferently/KidsLearnDifferently";
import QuotesHome from "./QuotesHome/QuotesHome";
import GetStarted from "../Shared/GetStarted/GetStarted";

class App extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <div className={classes.TopDiv}>
          {/* <PodTutoring /> */}
          <div className={classes.Text}>
            <h1>College Acceptances are rolling in...</h1>
            <h2>
              University of Michigan, USC, Cornell, NYU, UCLA, UCSB, Brown,
              Tulane, NYU, Kenyon, Duke, Barnard, Bard, Brandeis, Wisconsin, Cal
              Poly SLO, CU Boulder, Haverford, Boston College, Occidental,
              Loyola Marymount, BU, U of Portland, TCU, Cambridge School of
              Visual and Performing Arts, Spelman, Indiana ... and the list goes
              on!!
            </h2>
            <h1>Congratulations class of 2023!</h1>
          </div>

          <InfoBoxes />
        </div>
        <UpcomingTests />
        <OurValuesHome />
        <KidsLearnDifferently
          title="We also specialize in working with kids who learn differently."
          text="Every student learns differently. That’s why we offer educational
      counseling (focusing on test anxiety management and organizational
      skills), collegiate tutoring and Skype class offerings."
        />
        <QuotesHome />
        <GetStarted />
      </Fragment>
    );
  }
}

export default App;
