import React, { Component } from "react";
import classes from "./PracticeTestForm.module.css";
import image from "../../../images/sign-up-right-img.jpg";
import Button from "../../Shared/Button/Button";
import emailjs from "emailjs-com";
import axios from "axios";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

class PracticeTestForm extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      testType: "ACT",
      email: "",
      testDate: "",
      testDates: [],
      phone: "",
      workWithQuant: false,
      studPar: true, //true corresponds to student, false to parent
      additional: "",
    };
    this.date_sort_asc = (date1, date2) => {
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    };
    this.months = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };
    axios
      .get("https://quantumtutors-2b216.firebaseio.com/dates.json")
      .then((res) => {
        let dates = [];
        for (let key in res.data) {
          const hour = parseInt(res.data[key]["date"].substring(8, 10));
          console.log("hour", hour);
          const newDate = new Date(
            parseInt(res.data[key]["date"].substring(4, 8)),
            parseInt(res.data[key]["date"].substring(0, 2)) - 1,
            parseInt(res.data[key]["date"].substring(2, 4)),
            hour + res.data[key]["date"].includes("AM")
              ? 0
              : hour == 12
              ? 0
              : 12,
            parseInt(res.data[key]["date"].substring(10, 12))
          );
          console.log(newDate.getHours());
          dates.push(
            new Date(
              parseInt(res.data[key]["date"].substring(4, 8)),
              parseInt(res.data[key]["date"].substring(0, 2)) - 1,
              parseInt(res.data[key]["date"].substring(2, 4)),
              hour -
                1 +
                (res.data[key]["date"].includes("AM")
                  ? 0
                  : hour == 12
                  ? 0
                  : 12),
              parseInt(res.data[key]["date"].substring(10, 12))
            )
          );
        }
        dates.sort(this.date_sort_asc);
        this.setState({ testDates: dates });
      })
      .catch((err) => {
        alert(err);
      });
  }

  inputChangedHandler = (event, id) => {
    switch (id) {
      case "name":
        this.setState({ name: event.target.value });
        break;
      case "testType":
        this.setState({ testType: event.target.value });
        break;
      case "email":
        this.setState({ email: event.target.value });
        break;
      case "testDate":
        this.setState({ testDate: event.target.value });
        console.log(event.target.value);
        break;
      case "phone":
        this.setState({ phone: event.target.value });
        break;
      case "workWithQuant":
        this.setState({ workWithQuant: event.target.value === "Yes" });
        break;
      case "studPar":
        this.setState({ studPar: event.target.value === "Student" });
        break;
      case "additional":
        this.setState({ additional: event.target.value });
        break;
      default:
        break;
    }
  };

  formSubmitHandler = () => {
    emailjs.send(
      "gmail_service",
      "quantum_tutors",
      {
        name: this.state.name,
        workWithQuant: this.state.workWithQuant,
        studPar: this.state.studPar === true ? "Student" : "Parent",
        email: this.state.email,
        phone: this.state.phone,
        testDate: this.state.testDate,
        testType: this.state.testType,
        additional: this.state.additional,
      },
      "user_FIMaGb6x4xX7xwk9t5CMK"
    );
    emailjs
      .send(
        "gmail",
        "testconfirmation",
        {
          email: this.state.email,
          name: this.state.name,
          testType: this.state.testType,
          date: this.state.testDate,
        },
        "user_VkefG9Vg4MyMgutkS6DRW"
      )
      .then(
        (response) => {
          alert("Your response was received. We will contact you soon!");
        },
        function (error) {
          alert("An error occurred: " + error);
        }
      );
  };

  render() {
    return (
      <React.Fragment>
        <h1 style={{ textAlign: "center" }}>Sign up for a Practice Test</h1>
        <div className={classes.div}>
          <form className={classes.form}>
            <FormControl>
              <InputLabel color="secondary">Name</InputLabel>
              <Input
                color="secondary"
                value={this.state.name}
                onChange={(event) => this.inputChangedHandler(event, "name")}
                style={{ marginBottom: "10px" }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel color="secondary">Type of Test</InputLabel>
              <Select
                input={<Input />}
                onChange={(event) =>
                  this.inputChangedHandler(event, "testType")
                }
                value={this.state.testType}
                color="secondary"
              >
                <MenuItem value="ACT">ACT</MenuItem>
                <MenuItem value="SAT">SAT</MenuItem>
                <MenuItem value="PSAT">PSAT</MenuItem>
                <MenuItem value="HSPT">HSPT</MenuItem>
                <MenuItem value="ISEE">ISEE</MenuItem>
                <MenuItem value="SSAT">SSAT</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel color="secondary">Email</InputLabel>
              <Input
                color="secondary"
                value={this.state.email}
                onChange={(event) => this.inputChangedHandler(event, "email")}
                style={{ marginBottom: "10px" }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel color="secondary">Test Date</InputLabel>
              <Select
                input={<Input />}
                value={this.state.testDate}
                onChange={(event) =>
                  this.inputChangedHandler(event, "testDate")
                }
                color="secondary"
              >
                {this.state.testDates.map((date) => {
                  const hours = date.getHours() + 1;
                  return (
                    <MenuItem
                      key={date}
                      value={`${
                        this.months[date.getMonth() + 1]
                      } ${date.getUTCDate()}, ${date.getFullYear()}, ${
                        hours > 12 ? hours - 12 : hours
                      }:${
                        date.getMinutes() < 10
                          ? "0" + date.getMinutes()
                          : date.getMinutes()
                      }`}
                    >
                      {`${
                        this.months[date.getMonth() + 1]
                      } ${date.getUTCDate()}, ${date.getFullYear()}, ${
                        hours > 12 ? hours - 12 : hours
                      }:${
                        date.getMinutes() < 10
                          ? "0" + date.getMinutes()
                          : date.getMinutes()
                      }`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel color="secondary">Phone</InputLabel>
              <Input
                color="secondary"
                value={this.state.phone}
                onChange={(event) => this.inputChangedHandler(event, "phone")}
                style={{ marginBottom: "10px" }}
              />
            </FormControl>
            <FormControl>
              <FormLabel color="secondary">
                Do you currently work with Quantum Tutors?
              </FormLabel>
              <RadioGroup>
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                  checked={this.state.workWithQuant}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "workWithQuant")
                  }
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                  checked={!this.state.workWithQuant}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "workWithQuant")
                  }
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel color="secondary">
                Are you a student or a parent?
              </FormLabel>
              <RadioGroup>
                <FormControlLabel
                  value="Student"
                  control={<Radio />}
                  label="Student"
                  checked={this.state.studPar}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "studPar")
                  }
                />
                <FormControlLabel
                  value="Parent"
                  control={<Radio />}
                  label="Parent"
                  checked={!this.state.studPar}
                  onChange={(event) =>
                    this.inputChangedHandler(event, "studPar")
                  }
                />
              </RadioGroup>
            </FormControl>

            <TextField
              id="outlined-multiline-static"
              label="Additional"
              multiline
              rows={3}
              value={this.state.additional}
              onChange={(event) =>
                this.inputChangedHandler(event, "additional")
              }
              variant="outlined"
              style={{ marginBottom: "30px" }}
              color="secondary"
            />
            <Button onClick={this.formSubmitHandler}>Submit</Button>
          </form>
          {window.innerWidth > 900 ? <img src={image} alt="" /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default PracticeTestForm;
