import React, { Component } from "react";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import background from "../../../images/page-39bg.jpg";
import ISEEImg from "../../../images/isee-img.jpg";
import PurpleP from "../../../images/parallellogramPurple.jpg";
import Parallellogram from "../../Shared/Parallellogram/Parallellogram";
import GetStarted from "../../Shared/GetStarted/GetStarted";
import classes from "../../Shared/sharedStyles.module.css";
import TestForm from "../../Shared/PracticeTestForm/PracticeTestForm";

class ISEEHSPTSSAT extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <PageHeader
          background={background}
          header="ISEE/HSPT/SSAT"
          page="ISEE/HSPT/SSAT"
          text="Select private elementary, middle and high schools, as well as some Catholic and boarding schools, may require your child to take one of the following standardized assessment tests:"
        />
        <ul className={classes.text}>
          <li>ISEE (Independent School Entrance Exam)</li>
          <li>HSPT (High School Placement Test)</li>
          <li>SSAT (Secondary School Admission Test)</li>
        </ul>
        <img src={ISEEImg} alt="ISEE" className={classes.img} />
        <p className={classes.text}>
          We understand that it can be stressful having your elementary or
          middle school aged child prepare for a long, standardized exam.
          Quantum has a roster of likable, warm and dedicated tutors trained to
          make the process fun and interesting. Our materials are thorough and
          compressive ensuring the your child will go into the test feeling
          prepared!
        </p>
        <Parallellogram background={PurpleP}>
          <h2>
            “Anna - I must say that you and Jordan have arrived like angels and
            lit my son’s internal pilot.”
          </h2>
          <h4>– A Calabasas Parent</h4>
        </Parallellogram>
        <TestForm />
        <GetStarted />
      </div>
    );
  }
}

export default ISEEHSPTSSAT;
