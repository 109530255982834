import React, { useEffect, useState } from "react";
import PageHeader from "../Shared/PageHeader/PageHeader";
import background from "../../images/our-method-bg.jpg";
import Axios from "axios";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    Axios.get("https://quantumtutors-2b216.firebaseio.com/testimonials.json")
      .then((res) => {
        console.log("testimonials:", res);
        let testimonialArray = [];
        for (let key in res.data) {
          testimonialArray.push(res.data[key]);
        }
        setTestimonials(testimonialArray);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <PageHeader
        background={background}
        header="What Quantum Clients Say..."
        page="Testimonials"
      />
      {testimonials &&
        testimonials.map((testimonial) => (
          <div
            style={{
              textAlign: "center",
              margin: "3rem 10% 1rem 10%",
              borderLeft: "solid rgb(37, 151, 204, 0.2)",
              paddingLeft: "1rem",
            }}
          >
            <p style={{ fontSize: "1.4em", color: "#636363" }}>
              {`"${testimonial.quote}"`}
            </p>
            <p style={{ fontSize: "1em" }}>{`- ${testimonial.author}`}</p>
          </div>
        ))}
    </>
  );
};

export default Testimonials;
