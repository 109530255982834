import React from "react";
import classes from "./Service.module.css";
import Button from "../../Shared/Button/Button";
import { Link } from "react-router-dom";

const Service = (props) => (
  <div className={classes.topDiv}>
    <div className={classes.div} onClick={props.clicked}>
      <h2>{props.service}</h2>
      {props.arrowIcon}
    </div>
    {props.children}
    {props.visible ? (
      <React.Fragment>
        <Link to={props.link} className={classes.button}>
          <Button>Read More</Button>
        </Link>
        <Link to="sign-up-for-a-practice-test" className={classes.button}>
          <Button>Sign Up For A Practice Test</Button>
        </Link>
      </React.Fragment>
    ) : null}

    <hr style={{ marginTop: props.visible ? "45px" : "0" }} />
  </div>
);

export default Service;
