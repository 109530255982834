import React, { Component } from "react";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import background from "../../../images/page-39bg.jpg";
import SATImage from "../../../images/satpsat-img.jpg";
import Parallellogram from "../../Shared/Parallellogram/Parallellogram";
import GreenP from "../../../images/parallellogramGreen.jpg";
import GetStarted from "../../Shared/GetStarted/GetStarted";
import classes from "../../Shared/sharedStyles.module.css";
import TestForm from "../../Shared/PracticeTestForm/PracticeTestForm";

class SATPSAT extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <PageHeader background={background} header="SAT/PSAT" page="SAT/PSAT" />
        <img src={SATImage} alt="SAT" className={classes.img} />
        <p className={classes.text}>
          The SAT exam has four sections: reading, writing & language, Math
          without use of a calculator, Math with use of a calculator, and an
          optional essay. SAT math sections also include non-multiple choice
          questions to which the student must write in a response.
        </p>

        <p className={classes.text}>
          The best way to determine if the SAT is the best option for you is to
          take a practice test. SAT practice tests are free, and are followed by
          a director analysis to help you choose which test is best suited for
          you. That way, before signing up with us, you know exactly where you
          stand and we know where to begin.
        </p>
        <h1>What's the difference between an SAT and a PSAT?</h1>
        <p className={classes.text}>
          The PSAT is essentially a preliminary test taken in the fall of 11th
          grade. The PSAT is slightly shorter in time and length than an SAT,
          and is scored on a slightly different scale.
        </p>
        <Parallellogram background={GreenP}>
          <h2>
            “My tutor was extremely helpful in the process for preparing for the
            SATs. She was very professional while still keeping all of our
            sessions fun and engaging.”
          </h2>
          <h4>– Gloria Y, Mother of Zach</h4>
        </Parallellogram>
        <TestForm />
        <GetStarted />
      </div>
    );
  }
}

export default SATPSAT;
