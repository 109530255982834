import React from "react";
import classes from "./InfoBox.module.css";

const InfoBox = (props) => (
  <div className={classes.Text} style={{ backgroundColor: props.color }}>
    <img src={props.image} alt="" />
    <h3>{props.heading}</h3>
    <p>{props.text}</p>
  </div>
);

export default InfoBox;
