import React, { Component } from "react";
import PageHeader from "../Shared/PageHeader/PageHeader";
import background from "../../images/page-39bg.jpg";
import TestForm from "../Shared/PracticeTestForm/PracticeTestForm";
import GetStarted from "../Shared/GetStarted/GetStarted";

class PracticeTests extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <PageHeader
          background={background}
          header="Take a practice test! We want you to not only be smart but prepared as well."
          page="Practice Tests"
          text="In order to take new steps in your academic journey we must first define your destination. We need to find out what step one is first. Our practice tests offer a highly detailed example of your testing strengths and weaknesses, allowing us to provide guidance and resources that are tailored to your personal needs. We offer complimentary, obligation-free practice test sessions virtually every weekend. We don’t mind if you take a practice test before committing to lessons – rather, we encourage it! We provide practice tests for all college admission tests – including the SAT, PSAT, ACT, and SAT Subject Tests – not to mention all private school admission tests – including the ISEE, HSPT, and SSAT."
        />
        <TestForm />
        <GetStarted />
      </div>
    );
  }
}

export default PracticeTests;
