import React, { Component } from "react";
import MenuIcon from "@material-ui/icons/Dehaze";
import classes from "./Toolbar.module.css";
import quantumLogo from "../../images/quantumtutors_logo.png";
import { Link } from "react-router-dom";
import NavItem from "./NavItem/NavItem";

class Toolbar extends Component {
  state = {
    scrolled: false,
    showToolbar: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 60;
      if (!isTop && !this.state.scrolled) {
        this.setState({ scrolled: true });
      } else if (isTop && this.state.scrolled) {
        this.setState({ scrolled: false });
      }
    });
    window.addEventListener("click", (click) => {
      if (this.state.showToolbar && click.clientY > 68) {
        this.setState({ showToolbar: false });
      }
    });
  }

  toggleToolbar = () => {
    this.setState((prevState) => {
      return { showToolbar: !prevState.showToolbar };
    });
  };

  render() {
    return (
      <div>
        <ul
          className={
            this.state.scrolled
              ? classes.Toolbar + " " + classes.scrolled
              : classes.Toolbar
          }
        >
          <MenuIcon
            color="secondary"
            fontSize="large"
            style={{ padding: "16px 0 0 15px" }}
            onClick={this.toggleToolbar}
          />
          <Link to="/" onClick={() => this.setState({ showToolbar: false })}>
            <img
              src={quantumLogo}
              alt="Quantum Tutors"
              className={classes.img}
            />
          </Link>
        </ul>
        {this.state.showToolbar ? (
          <div
            className={
              this.state.scrolled ? classes.clickedScrolled : classes.clicked
            }
          >
            <NavItem link="/our-method" scrolled={this.state.scrolled}>
              Our Method
            </NavItem>
            <NavItem link="/meet-the-team" scrolled={this.state.scrolled}>
              Meet The Team
            </NavItem>
            <NavItem link="/testimonials" scrolled={this.state.scrolled}>
              Testimonials
            </NavItem>
            <NavItem
              link="/sign-up-for-a-practice-test"
              scrolled={this.state.scrolled}
            >
              Practice Tests
            </NavItem>
            <NavItem link="/contact" scrolled={this.state.scrolled}>
              Contact Us
            </NavItem>
            <NavItem link="/act-preparation" scrolled={this.state.scrolled}>
              ACT
            </NavItem>
            <NavItem link="/sat-psat" scrolled={this.state.scrolled}>
              SAT/PSAT
            </NavItem>
            <NavItem link="/isee-hspt-ssat" scrolled={this.state.scrolled}>
              ISEE/HSPT/SSAT
            </NavItem>
            <NavItem link="/academic" scrolled={this.state.scrolled}>
              Academic
            </NavItem>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Toolbar;
