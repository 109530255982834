import React from "react";
import classes from "./Dropdown.module.css";
import { Link } from "react-router-dom";

const Dropdown = (props) => {
  let menu = null;
  let topMargin = props.scrolled ? "50px" : "68px";
  if (props.services) {
    let leftMargin = props.scrolled ? "10%" : "13%";
    menu = (
      <div
        className={classes.div}
        style={{ marginLeft: leftMargin, marginTop: topMargin }}
        onMouseLeave={props.offHover}
      >
        <Link to="/act-preparation" className={classes.Link}>
          ACT
        </Link>
        <Link to="/sat-psat" className={classes.Link}>
          SAT/PSAT
        </Link>
        <Link to="/isee-hspt-ssat" className={classes.Link}>
          ISEE/HSPT/SSAT
        </Link>
        <Link to="/academic" className={classes.Link}>
          Academic
        </Link>
      </div>
    );
  } else if (props.tutoring) {
    let leftMargin = props.scrolled ? "31%" : "33%";
    menu = (
      <div
        className={classes.div}
        style={{ marginLeft: leftMargin, marginTop: topMargin }}
        onMouseLeave={props.offHover}
      >
        <Link to="/our-method" className={classes.Link}>
          Our Method
        </Link>
        <Link to="/meet-the-team" className={classes.Link}>
          Meet The Team
        </Link>
      </div>
    );
  }
  return menu;
};

export default Dropdown;
