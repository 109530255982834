import React from "react";
import classes from "./OurValuesHome.module.css";
import Button from "../../Shared/Button/Button";
import { Link } from "react-router-dom";

const OurValuesHome = () => (
  <div className={classes.div}>
    <h1>Our Values</h1>
    <p>
      Our mission is to provide professional guidance and support to students
      and families as they navigate their way through elementary, middle and
      high school and prepare for college. At the heart of our mission is
      enhancing and distilling each student’s true potential inside and outside
      the classroom. Our focus is on delivering an individualized service
      tailored to the unique educational needs of each student and family.
    </p>
    <Link to="/our-method" style={{ textDecoration: "none" }}>
      <Button>Read More</Button>
    </Link>
  </div>
);

export default OurValuesHome;
