import React, { Fragment, Component } from "react";
import classes from "./MeetTheTeam.module.css";
import TutorCard from "./TutorCard/TutorCard";
import GetStarted from "../Shared/GetStarted/GetStarted";
import PageHeader from "../Shared/PageHeader/PageHeader";
import background from "../../images/bg-team.jpg";

class MeetTheTeam extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <PageHeader
          background={background}
          header="Meet The Team"
          page="Meet The Team"
        />
        <div className={classes.div2}>
          <div>
            {this.props.cards1.map((card) => {
              let name = Object.keys(card);
              return <TutorCard key={name} name={name} text={card[name]} />;
            })}
          </div>
          <div>
            {this.props.cards2.map((card) => {
              let name = Object.keys(card);
              return <TutorCard key={name} name={name} text={card[name]} />;
            })}
          </div>
        </div>
        <GetStarted />
      </Fragment>
    );
  }
}

export default MeetTheTeam;
