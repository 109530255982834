import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#26c687",
    },
    secondary: {
      main: "#2597cc",
    },
    height: "100%",
    color: "white",
  },
});

export default theme;
