import React, { Component } from "react";
import textClasses from "../Shared/sharedStyles.module.css";
import classes from "./Service/Service.module.css";
import PageHeader from "../Shared/PageHeader/PageHeader";
import background from "../../images/services-bg.jpg";
import Service from "./Service/Service";
import ACTImage from "../../images/sar-img.jpg";
import SATImage from "../../images/satpsat-img.jpg";
import ISEEImg from "../../images/isee-img.jpg";
import topImage from "../../images/academic-img.jpg";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

class Services extends Component {
  state = {
    ACT: false,
    SAT: false,
    ISEE: false,
    Academic: false,
  };

  toggleServiceHandler = (service) => {
    switch (service) {
      case "ACT":
        this.setState((prevState) => ({ ACT: !prevState.ACT }));
        break;
      case "SAT":
        this.setState((prevState) => ({ SAT: !prevState.SAT }));
        break;
      case "ISEE":
        this.setState((prevState) => ({ ISEE: !prevState.ISEE }));
        break;
      case "Academic":
        this.setState((prevState) => ({ Academic: !prevState.Academic }));
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const downArrow = <KeyboardArrowDownIcon fontSize="large" />;
    const upArrow = <KeyboardArrowUpIcon fontSize="large" />;
    return (
      <div>
        <PageHeader
          background={background}
          header="Our Services"
          page="Services"
        />
        <Service
          service="ACT"
          arrowIcon={this.state.ACT ? upArrow : downArrow}
          clicked={() => this.toggleServiceHandler("ACT")}
          link="/act-preparation"
          visible={this.state.ACT}
        >
          {this.state.ACT ? (
            <div>
              <p className={textClasses.text} style={{ margin: "0" }}>
                Similar to the SAT, the ACT are slightly different in that it
                includes science reasoning, where the SAT does not, in addition
                to English, math and reading, with an optional essay section.
                Colleges use ACT scores for admissions and merit-based
                scholarships. The best way to determine if the ACT is the best
                option for you is to take a practice test. ACT practice tests
                are free, and are followed by a director analysis to help you
                choose which test is best suited for you. That way, before
                signing up with us, you know exactly where you stand and we know
                where to begin.
              </p>
              {window.innerWidth > 900 ? (
                <img src={ACTImage} alt="ACT" className={classes.img} />
              ) : null}
            </div>
          ) : null}
        </Service>
        <Service
          service="SAT/PSAT"
          arrowIcon={this.state.SAT ? upArrow : downArrow}
          clicked={() => this.toggleServiceHandler("SAT")}
          link="/sat-psat"
          visible={this.state.SAT}
        >
          {this.state.SAT ? (
            <div>
              <p className={textClasses.text} style={{ margin: "0" }}>
                Similar to the ACT, the SAT are slightly different in that they
                don’t contain a science reasoning section like the ACT does.
                With four sections: reading, math, writing & language and an
                optional essay, the SAT is used for college admissions and
                merit-based scholarships. The best way to determine if the SAT
                is the best option for you is to take a practice test. SAT
                practice tests are free, and are followed by a director analysis
                to help you choose which test is best suited for you. That way,
                before signing up with us, you know exactly where you stand and
                we know where to begin.
              </p>
              {window.innerWidth > 900 ? (
                <img src={SATImage} alt="SAT" className={classes.img} />
              ) : null}{" "}
            </div>
          ) : null}
        </Service>
        <Service
          service="ISEE/HSPT/SSAT"
          arrowIcon={this.state.ISEE ? upArrow : downArrow}
          clicked={() => this.toggleServiceHandler("ISEE")}
          link="/isee-hspt-ssat"
          visible={this.state.ISEE}
        >
          {this.state.ISEE ? (
            <div>
              <p className={textClasses.text} style={{ margin: "0" }}>
                The SAT/ACT are standardized tests for college admissions. But
                for select elementary, middle and high schools, as well as some
                Catholic and boarding schools, there is another set of rigorous
                and competitive assessment tests, these being the most common:
              </p>
              <ul className={textClasses.text} style={{ margin: "0" }}>
                <li>ISEE (Independent School Entrance Exam)</li>
                <li>HSPT (High School Placement Test)</li>
                <li>SSAT (Secondary School Admission Test)</li>
              </ul>
              {window.innerWidth > 900 ? (
                <img src={ISEEImg} alt="ISEE" className={classes.img} />
              ) : null}{" "}
            </div>
          ) : null}
        </Service>
        <Service
          service="Academic"
          arrowIcon={this.state.Academic ? upArrow : downArrow}
          clicked={() => this.toggleServiceHandler("Academic")}
          link="/academic"
          visible={this.state.Academic}
        >
          {this.state.Academic ? (
            <div>
              <p className={textClasses.text} style={{ margin: "0" }}>
                Between schoolwork and extracurricular activities, being a
                student can be overwhelming, and academic assistance needs can
                vary and change over time. That’s why we do not require an
                upfront commitment to a package of lessons. Whether you’re
                looking for weekly lessons or more intermittent help such as
                assistance on writing a paper or studying for an AP exam, a
                director will happily discuss your situation and recommend a
                positive and resourceful solution.
              </p>
              {window.innerWidth > 900 ? (
                <img src={topImage} alt="" className={classes.img} />
              ) : null}{" "}
            </div>
          ) : null}
        </Service>
      </div>
    );
  }
}

export default Services;
