import React from "react";
import classes from "./PageHeader.module.css";
import { Link } from "react-router-dom";

const PageHeader = (props) => {
  const background = props.background;
  return (
    <React.Fragment>
      <div
        className={classes.TopDiv}
        style={{ backgroundImage: "url(" + background + ")" }}
      >
        {props.subheader && (
          <div
            style={{
              zIndex: 1,
              position: "absolute",
              top: "70px",
              left: "50%",
              width: "85%",
            }}
          >
            <div
              style={{
                position: "relative",
                left: "-50%",
                textAlign: "center",
              }}
            >
              {props.subheader}
            </div>
          </div>
        )}
        <div style={{ height: "200px" }} />
        <div className={classes.div2}>
          {window.innerWidth > 900 && (
            <>
              <Link to="/">
                <p>Home</p>
              </Link>
              <p>/ {props.page}</p>
            </>
          )}
        </div>
      </div>
      <h1>{props.header}</h1>
      <p className={classes.text}>{props.text}</p>
    </React.Fragment>
  );
};

export default PageHeader;
