import React from "react";
import classes from "./KidsLearnDifferently.module.css";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const KidsLearnDifferently = (props) => (
  <div className={classes.div}>
    <h1>{props.title}</h1>
    <p>{props.text}</p>
    <Link to="/contact" style={{ textDecoration: "none" }}>
      <Button>contact us</Button>
    </Link>
  </div>
);

export default KidsLearnDifferently;
